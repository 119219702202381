// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment: any = {
    production: false,
    client_env: 'local',
    baseUrl: 'http://ops.local.macartevacances.fr/v1',
    CGU_MACARTEVACANCES: 'https://www.macartevacances.fr/conditions-generales',
    MENTIONS_MACARTEVACANCES: 'https://www.macartevacances.fr/mentions-legales',
    POLITIQUE_MACARTEVACANCES: 'https://www.macartevacances.fr/politique-de-confidentialite',
    CGU_TREEZOR: 'https://uploads-ssl.webflow.com/5dd310bf0e47ad35614be392/5f073a75aeed13aa59f12523_Treezor_%20Otium%20-%20CGU%20Carte.pdf',
    HIPAY_PUBLIC_LOGIN: '94684974.stage-secure-gateway.hipay-tpp.com',
    HIPAY_PUBLIC_PASSWORD: 'Test_0Fiu8ET1E4SxhNTlTQ9bY8gs',
    HIPAY_ENVIRONNEMENT: 'stage',
    G_TAG: 'UA-158358329-4',
    G_TAG_CSE: 'UA-158358329-4',
    G_MAP: 'AIzaSyBcODGvzgaUASadSDqg2vZcNJoTMktBvfE',
    RECAPTCHA_SITE_KEY: '6LfGKO0UAAAAAFLClc2q5cnqQoEWX9m5PzYBRJ_N',
    INTERCOM: 'oo5boauo'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
